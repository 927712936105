import React from 'react';

export const CloseIcon = ({
  color = 'white',
  width = 16,
  height = 17,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8.5C0 4.076 3.576 0.5 8 0.5C12.424 0.5 16 4.076 16 8.5C16 12.924 12.424 16.5 8 16.5C3.576 16.5 0 12.924 0 8.5ZM10.872 12.5L12 11.372L9.128 8.5L12 5.628L10.872 4.5L8 7.372L5.128 4.5L4 5.628L6.872 8.5L4 11.372L5.128 12.5L8 9.628L10.872 12.5Z"
        fill={color}
      />
    </svg>
  );
};
