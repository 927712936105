import { axiosApiInstance } from '../config/axios-instance';
import { API_ENDPOINT_PAYROLL, defaultHeaders } from '../config/config';
import { readDataPayroll } from '../helpers/payroll.helper';
import { warningToast } from '../helpers/toast.helper';
import { showErrorMessage } from '../utils/showErrorService';
import { enterpriseIdHeader } from './authActions';
import { showErrorAction } from './generalAction';
import { types } from './types';
import axios from "axios";

const urlDocument = `${API_ENDPOINT_PAYROLL}/documentnomina/api`;
/**
 * Consulta listado nominas a reportar
 * @param {*} data
 * @returns
 */
export const getPayrollToReportAction =
  (data) => async (dispatch, getState) => {
    try {
      changeLoadingPayrollReport(dispatch, true);

      data = {
        ...data,
        EmpresaId: enterpriseIdHeader(),
      };

      if (!data.LoadMore) {
        dispatch({
          type: types.FILL_PAYROLL_REPORT,
          payload: [],
        });
      }
      const { payrollListReport } = getState().payrollReportReducer;

      const resp = await axiosApiInstance.post(
        `${urlDocument}/Documento/GetPayrollToReport`,
        data,
        defaultHeaders(),
      );

      const soportes = setSelectedItems(
        getState,
        resp?.data?.result?.listadosoportes,
      );

      const newList = data.LoadMore
        ? [...payrollListReport, ...soportes]
        : soportes;
      dispatch({
        type: types.FILL_PAYROLL_REPORT,
        payload: newList,
      });

      /**
       * Información general nomina
       */
      const payroll = resp?.data?.result;
      dispatch({
        type: types.SET_DATA_PAYROLL,
        payload: payroll,
      });

      if (newList.length > 0 && !data.LoadMore) {
        const nominaId = newList[0].nominaid;
        const empleadoId = newList[0].empleadoid;
        const soporte = await getSupportEmployee(nominaId, empleadoId);

        dispatch({
          type: types.FILL_SUPPORT_EMPLOYEE,
          payload: soporte,
        });

        dispatch({
          type: types.FILL_INCOMES_PAYROLL,
          payload: soporte.ingresos,
        });

        dispatch({
          type: types.FILL_EXPENSES_PAYROLL,
          payload: soporte.deducciones,
        });
      }

      if (newList.length === 0 && !data.LoadMore) {
        dispatch({
          type: types.FILL_SUPPORT_EMPLOYEE,
          payload: null,
        });
      }
    } catch (err) {
      showErrorMessage(
        err,
        'Ha ocurrido un error consultando información nomina reportada.',
      );
    } finally {
      changeLoadingPayrollReport(dispatch, false);
    }
  };

const changeLoadingPayrollReport = (dispatch, status) => {
  dispatch({
    type: types.LOADING_PAYROLL_REPORT,
    payload: status,
  });
};

const setSelectedItems = (getState, soportes) => {
  const { payrollListChecked } = getState().payrollReportReducer;
  soportes = soportes ?? [];

  let newList = soportes.map((item) => ({
    ...item,
    seleccionado: false,
  }));

  for (let i = 0; i < payrollListChecked.length; i++) {
    const checked = payrollListChecked[i];
    const index = newList.findIndex(
      (item) => item.empleadoid === checked.empleadoid,
    );
    if (index !== -1) {
      newList[index].seleccionado = true;
    }
  }
  return newList;
};

/**
 * Agrega itema a listado marcados
 * @param {*} id identificador item nomina
 * @returns
 */
export const addCheckedItemAction =
  (empleadoid, checked) => async (dispatch) => {
    dispatch({
      type: types.ADD_CHECKED_PAYROLL,
      payload: {
        empleadoid,
        checked,
      },
    });
  };

/**
 * Marca  item nomina a reportar
 * @param {*} id identificador item nomina
 * @param {*} seleccion  estado
 * @returns
 */
export const checkAllPayrollItemsAction = (status) => async (dispatch) => {
  dispatch({
    type: types.CHECK_ALL_PAYROLL,
    payload: status,
  });
};

/**
 * Actualiza información filtro
 * @param {*} data
 * @returns
 */
export const updateFilterDataAction = (data) => async (dispatch) => {
  dispatch({
    type: types.SET_FILTER_PAYROLL,
    payload: data,
  });
};

/**
 * Consulta información soporte de nomina para empleado
 * @param {*} nominaId
 * @param {*} empleadoId
 * @returns
 */
export const getSupportEmployee = async (nominaId, empleadoId) => {
  try {
    const config = defaultHeaders();
    Object.assign(config.headers, {
      nominaId: nominaId,
      empleadoId: empleadoId,
    });
    const resp = await axiosApiInstance.get(
      `${urlDocument}/Documento/GetPayrollToSupport`,
      config,
    );

    const soporte = readDataPayroll(resp?.data?.result);
    return soporte;
  } catch (err) {
    showErrorMessage(
      err,
      'Ha ocurrido un error consultando soporte de nomina.',
    );
  }
};

/**
 * Consulta información soporte de nomina
 * @param {*} nominaId
 * @param {*} empleadoId
 * @returns
 */
export const getPayrollToSupportAction =
  (nominaId, empleadoId) => async (dispatch) => {
    try {
      changeLoadingSupport(dispatch, true);

      const soporte = await getSupportEmployee(nominaId, empleadoId);

      dispatch({
        type: types.FILL_SUPPORT_EMPLOYEE,
        payload: soporte,
      });

      dispatch({
        type: types.FILL_INCOMES_PAYROLL,
        payload: soporte.ingresos,
      });

      dispatch({
        type: types.FILL_EXPENSES_PAYROLL,
        payload: soporte.deducciones,
      });
    } catch (err) {
      showErrorMessage(
        err,
        'Ha ocurrido un error consultando soporte de nomina.',
      );
    } finally {
      changeLoadingSupport(dispatch, false);
    }
  };

const changeLoadingSupport = (dispatch, status) => {
  dispatch({
    type: types.LOADING_GET_SUPPORT,
    payload: status,
  });
};

/**
 * Confirma soporte de nomina
 * @param {*} detallenominaid identificador soporte
 * @param {*} estado Nuevo estado (true, false)
 * @returns
 */
const confirmPayrollItem = async (dispatch, detallenominaid, estado) => {
  try {
    dispatch({
      type: types.LOADING_CONFIRM_PAYROLL,
      payload: true,
    });

    const config = defaultHeaders();
    Object.assign(config.headers, {
      detallenominaid: detallenominaid,
    });

    const data = {
      Estadonomina: estado,
    };

    const resp = await axiosApiInstance.put(
      `${urlDocument}/Documento/UpdatePayrollStatus`,
      data,
      config,
    );

    if (resp) {
      dispatch({
        type: types.CONFIRM_PAYROLL,
        payload: {
          estado,
          detallenominaid,
        },
      });
    }
  } catch (err) {
    showErrorMessage(
      err,
      'Ha ocurrido un error en confirmación de soporte de nomina',
    );
  } finally {
    dispatch({
      type: types.LOADING_CONFIRM_PAYROLL,
      payload: false,
    });
  }
};

/**
 * Confirma soporte de nomina
 * @param {*} detalleNominaId
 * @param {*} estado
 * @returns
 */
export const updatePayrollStatusAction =
  (detallenominaid, estado) => async (dispatch, getState) => {
    await confirmPayrollItem(dispatch, detallenominaid, estado);

    //Consulta totales
    const { Mes, Anio } = getState().payrollReportReducer.filterData;
    await dispatch(getTotalDataPayrollReportAction({ Mes, Anio }));
  };

export const updateIncomeAndExpenseDocument = async (
  dispatch,
  getState,
  detalleNominaId,
) => {
  try {
    const { activeSuport } = getState().payrollReportReducer;
    const { Mes, Anio } = getState().payrollReportReducer.filterData;

    const config = defaultHeaders();
    Object.assign(config.headers, {
      pDetalleNominaId: detalleNominaId,
    });

    const resp = await axiosApiInstance.get(
      `${urlDocument}/Documento/GetConceptToSupport`,
      config,
    );

    //Consulta totales
    await dispatch(getTotalDataPayrollReportAction({ Mes, Anio }));

    let ingresos = resp?.data?.result?.ingresos ?? [];
    ingresos = ingresos.map((item) => ({
      ...item,
      identificador: item.id,
    }));

    let deducciones = resp?.data?.result?.deducciones ?? [];
    deducciones = deducciones.map((item) => ({
      ...item,
      identificador: item.id,
    }));

    /**
     * Quita Confirmación de nomina previamente confirmada
     */
    if (activeSuport?.estadonominaid === 1) {
      await confirmPayrollItem(dispatch, detalleNominaId, false);
      dispatch({
        type: types.CONFIRM_PAYROLL,
        payload: {
          estado: false,
          detallenominaid: detalleNominaId,
        },
      });
    }

    dispatch({
      type: types.FILL_INCOMES_PAYROLL,
      payload: ingresos,
    });

    dispatch({
      type: types.FILL_EXPENSES_PAYROLL,
      payload: deducciones,
    });

    dispatch({
      type: types.UPDATE_TOTAL_PAYROLL,
      payload: {
        detallenominaid: detalleNominaId,
        valornomina: resp?.data?.result?.total ?? 0,
      },
    });
  } catch (err) {
    showErrorMessage(
      err,
      'Ha ocurrido un error actualizando totales de documento.',
    );
  }
};

/**
 * Inicia transmisión de nomina
 * @param {*} data Listado nominas
 * @returns
 */
export const transmitPayrollSupportsAction = (data) => async (dispatch) => {
  try {
    const body = {
      Detallesnomina: [...data],
    };

    changeLoadingTransmission(dispatch, true);
    await axiosApiInstance.post(
      `${urlDocument}/Documento/PayrollDetailTransmission`,
      body,
      defaultHeaders(),
    );
  } catch (err) {
    showErrorMessage(
      err,
      'Ha ocurrido un error, No se ha podido iniciar transmision',
    );
  } finally {
    changeLoadingTransmission(dispatch, false);
  }
};

export const startTransmissionStatusAction =
  (detalleId, estadonominaId, empleadoId, mesNomina) => async (dispatch) => {
    const data = {
      estadonominaId,
      detalleId,
      empleadoId,
      mesNomina,
    };

    try {
      dispatch({
        type: types.START_TRANSMISSION,
        payload: data,
      });
    } catch (err) {
      showErrorMessage(
        err,
        'Ha ocurrido un error, ejecutando la transmision de la nómina.',
      );
    }
  };

const changeLoadingTransmission = (dispatch, status) => {
  dispatch({
    type: types.LOADING_TRANSMISSION,
    payload: status,
  });
};

/**
 * Action para actualizar fecha nomina empleados
 * @param {*} month
 * @param {*} year
 * @returns
 */
export const updatePeriodDateAction =
  (month, year, paydate = null) =>
    async (dispatch) => {
      dispatch({
        type: types.SET_DATE_INSTANCE,
        payload: { month, year, paydate },
      });
    };

/**
 * Actualiza estado transmision
 * @param {*} data
 * @returns
 */
export const updateStatusTransmissionAction =
  (data) => async (dispatch, getState) => {
    const { filterData, nominaid } = getState().payrollReportReducer;
    if (nominaid === data.nominaid) {
      const { Mes, Anio } = filterData;
      await dispatch(getTotalDataPayrollReportAction({ Mes, Anio }));
      dispatch({
        type: types.UPDATE_STATUS_TRANSMISSION,
        payload: data,
      });
    }
  };

/**
 * Valida si se inicia el proceso de transmision con la repsuesta del signalR
 * @param {*} data
 * @returns
 */
export const getStatusTransmissionAction = (data) => async (dispatch) => {
  dispatch({
    type: types.START_PAYROLL_TRANSMISSION,
    payload: data,
  });
};

/**
 * Actualiza fecha de pago
 * @param {*} data
 * @returns
 */
export const updateDatePayAction =
  (fecha, handleError) => async (dispatch, getState) => {
    try {
      const { Mes, Anio } = getState().payrollReportReducer.filterData;

      changeLoadingPayDate(dispatch, true);
      const data = {
        Anio: Anio,
        Mes: Mes,
        EmpresaId: enterpriseIdHeader(),
        FechaPago: fecha,
      };

      await axiosApiInstance.post(
        `${urlDocument}/Documento/UpdatePayDate`,
        data,
        defaultHeaders(),
      );
    } catch (err) {
      showErrorMessage(
        err,
        'Ha ocurrido un error, No se ha podido actualizar fecha de pago',
      );
      handleError();
    } finally {
      changeLoadingPayDate(dispatch, false);
    }
  };

const changeLoadingPayDate = (dispatch, status) => {
  dispatch({
    type: types.LOADING_PAY_DATE,
    payload: status,
  });
};

/**
 * Action para tomar los datos y devolver total de empleados junto con el valor de nómina
 * @param {*} data, header de la peticion POST {Anio, Mes, EmpresaId }
 * @returns
 */

export const getTotalDataPayrollReportAction =
  (dataTotal) => async (dispatch) => {
    try {
      changeLoadingTotal(dispatch, true);

      dataTotal = {
        ...dataTotal,
        EmpresaId: enterpriseIdHeader(),
      };

      const urlPayrollTotal = `${API_ENDPOINT_PAYROLL}/documentnomina/api/Documento/ConsultPayrollTotals`;
      const { data } = await axiosApiInstance.post(
        urlPayrollTotal,
        dataTotal,
        defaultHeaders(),
      );

      dispatch({
        type: types.GET_TOTAL_DATA_PAYROLL,
        payload: data.result,
      });
    } catch (err) {
      showErrorMessage(
        err,
        'Ha ocurrido un error, consultando total de nomina',
      );
    } finally {
      changeLoadingTotal(dispatch, false);
    }
  };

const changeLoadingTotal = (dispatch, status) => {
  // dispatch({
  //   type: types.LOADING_TOTAL_PAYROLL,
  //   payload: status,
  // });
};

/**
 * Limpia reducer
 * @returns
 */
export const cleanPayrollReportAction = () => async (dispatch) => {
  dispatch({
    type: types.CLEAN_PAYROLL_REPORT,
  });
};

/**
 * Actualiza información transmision
 * @param {*} data
 * @returns
 */
export const updateTransmissionStatusAction = (data) => async (dispatch) => {
  dispatch({
    type: types.UPDATE_DATA_TRANSMISSION,
  });
};

/**
 * Asigna lista de nominas seleccionadas para reporte
 * @param {*} list Listado
 * @returns
 */
export const setCheckedPayrollListAction = (list) => async (dispatch) => {
  dispatch({
    type: types.SET_CHECKED_PAYROLL,
    payload: list,
  });
};

/**
 * Action para ingresar y actualizar observaciones de nómina
 * @param {*} remark
 * @returns
 */
/* export const UpdateRemarksPayrollAction = (remark) => async (dispatch) => {
  dispatch({
    type: types.ADD_REMARKS_PAYROLL,
    payload: remark,
  });
}; */

const changeLoadingObservation = (status) => {
  return {
    type: types.LOADING_OBSERVATION,
    payload: status,
  };
};

export const UpdateObservationPayrollAction =
  (observation) => async (dispatch, getState) => {
    try {
      dispatch(changeLoadingObservation(true));
      const { detallenominaid } = getState().payrollReportReducer.activeSuport;
      console.log('idAction', detallenominaid);
      const urlDocumentObservation = `${API_ENDPOINT_PAYROLL}/documentnomina/api/Documento/UpdateObservation`;

      const body = {
        DetalleNominaId: detallenominaid,
        Observation: observation,
      };

      console.log('Enviando--->', detallenominaid);

      const { data } = await axiosApiInstance.post(
        urlDocumentObservation,
        body,
        defaultHeaders(),
      );

      console.log('result', data);

      dispatch({
        type: types.ADD_OBSERVATION_PAYROLL,
        payload: observation,
      });
    } catch {
      showErrorAction('Error al actualizar las observaciones.');
    } finally {
      dispatch(changeLoadingObservation(false));
    }
  };

/**
 * Consulta nómina para previsualizarla
 * @param {*} nominaid Identificador unico de nomina
 */
export const getPayrollPreview = (nominaId) => async (dispatch) => {
  try {
    dispatch(changeLoadingPayrollPreview(true));
    const url = `${urlDocument}/documento/PrevisualizarNomina`;
    const config = defaultHeaders();

    Object.assign(config.headers, {
      pDetalleNominaId: nominaId,
      pEmpresaId: enterpriseIdHeader(),
    });

    const response = await axiosApiInstance.get(url, config);

    dispatch({
      type: types.GET_PAYROLL_PREVIEW,
      payload: response?.data?.result ?? null,
    });
  } catch (err) {
    showErrorMessage(err, 'Error consultando el detalle de nómina.');
  } finally {
    dispatch(changeLoadingPayrollPreview(false));
  }
};

const changeLoadingPayrollPreview = (status) => {
  return {
    type: types.LOADING_PAYROLL_PREVIEW,
    payload: status,
  };
};

export const clearPayrollPreview = () => {
  return {
    type: types.CLEAN_PAYROLL_PREVIEW,
    payload: null,
  };
};

/**
 * Consulta información  para generar reporte de documentos soportes con rango de fechas
 * @param {string} pFechaDesde Fecha de inicio
 * @param {string} pFechaHasta Fecha de fin
 * @param {function} setReport callback, trae la información del api y la setea en el estado
 */
export const getPayrollReportAction =
  (pFechaDesde, pFechaHasta, setReport, changeLoadingStatus) => async () => {
    try {
      changeLoadingStatus(true);
      const config = defaultHeaders();
      Object.assign(config.headers, {
        pEmpresaId: `${enterpriseIdHeader()}`,
        pFechaDesde: pFechaDesde,
        pFechaHasta: pFechaHasta,
      });

      const { data } = await axiosApiInstance.get(
        `${urlDocument}/Documento/GenerarInformesNomina`,
        config,
      );

      if (data.result === null)
        warningToast(
          'Upsss...!',
          'Sin información en las fechas indicadas. Por favor verifique el rango seleccionado.',
        );

      setReport(data.result ?? []);
    } catch (err) {
      showErrorMessage(
        err,
        'Error consultando el informe de nómina para el rango seleccionado.',
      );
    } finally {
      changeLoadingStatus(false);
    }
  };


export const downloadPayrollGroupedReport = (pFechaDesde, pFechaHasta, changeLoadingStatus, callback) => async () => {
  try {
    changeLoadingStatus(true);
    const pEmpresaId = enterpriseIdHeader();

    if (!pEmpresaId) {
      throw new Error("El ID de la empresa no está definido.");
    }

    const config = {
      headers: defaultHeaders().headers,
      responseType: "blob",
    };

    const url = `${urlDocument}/Documento/GenerarInformesNominaAgrupado?pEmpresaId=${pEmpresaId}&pFechaDesde=${pFechaDesde}&pFechaHasta=${pFechaHasta}`;

    try {
      // Realizar el consumo inicial
      const response = await axios.get(url, config);

      // Crear el archivo para descarga
      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      const nameReport = `ReporteNomina${pFechaDesde}_${pFechaHasta}.xlsx`;

      link.href = fileUrl;
      link.setAttribute("download", nameReport);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      if (callback) callback(true);
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        if (status === 510) {
          showErrorMessage(error,
            "No se encontraron registros de nómina para el período seleccionado. Por favor, intente con otro rango de fechas."
          );
          if (callback) callback(false);
        } else if (status === 500) {
          const errorMessage = await error.response.data.text();
          showErrorMessage(
            errorMessage || "Ocurrió un error inesperado en el servidor."
          );
          if (callback) callback(false);
        } else {
          throw new Error(
            `Ocurrió un error con el servidor. Código de estado: ${status}`
          );
        }
      } else {
        throw error;
      }
    }
  } catch (err) {
    showErrorMessage(err, "Error al descargar el informe. Por favor, intente nuevamente.");
  } finally {
    changeLoadingStatus(false);
  }
};

export const downloadPayrollReportEmployee = (empresaId, changeLoadingStatus) => async () => {
  try {
    changeLoadingStatus(true);
    const pEmpresaId = enterpriseIdHeader();

    if (!pEmpresaId) {
      throw new Error('El ID de la empresa no puede estar vacío.');
    }

    const config = {
      headers: defaultHeaders().headers,
      responseType: 'blob',
    };

    const url = `${urlDocument}/Documento/GenerarInformeEmpleado?pEmpresaId=${pEmpresaId}`;
    const response = await axios.get(url, config);

    const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    const nameReportEmployee = `ReporteNominaEmpleado_Empresa_${empresaId}.xlsx`;

    link.href = fileUrl;
    link.setAttribute('download', nameReportEmployee);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (err) {
    showErrorMessage(err, 'Error al descargar el informe. Por favor, intente nuevamente.');
  } finally {
    changeLoadingStatus(false);
  }
};



