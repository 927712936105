import {
  ADD_NEW_EXPENSE_EMPLOYEE,
  ADD_NEW_INCOME_EMPLOYEE,
  CHANGE_EDITION_EMPLOYEE_STATE,
  DELETE_EXPENSE_EMPLOYEE,
  DELETE_INCOME_EMPLOYEE,
  FILL_SUPPORT_EMPLOYEE_DETAIL,
  GET_EMPLOYEES,
  GET_EMPLOYEES_RESUME_LIST,
  LOAD_EMPLOYEE,
  LOAD_EMPLOYEE_DETAIL,
  LOADING_DETAIL_EMPLOYEE,
  LOADING_GET_EMPLOYEES,
  LOADING_RETIRE_EMPLOYEE,
  OPEN_MASSIVE_LOADING_EMPLOYEE,
  OPEN_REGISTER_EMPLOYEE,
  RESET_REGISTER_FORM,
  RETIRE_EMPLOYEE,
  SAVE_BASIC_DATA_EMPLOYEE,
  SAVE_CONTRACT_DATA_EMPLOYEE,
  SAVE_NOVELTIES_DATA_EMPLOYEE,
  SAVE_PAYMENT_DATA_EMPLOYEE,
  SAVE_RESPONSE_CREATE_PROCESS,
  SELECTED_ID_EMPLOYEE,
  SET_REINTEGRE_EMPLOYEE,
  types,
  UPDATE_CHANGE_STATUS,
  UPDATE_EXPENSE_EMPLOYEE,
  UPDATE_FAVORITE_EMPLOYEE,
  UPDATE_INCOME_EMPLOYEE,
  UPDATE_SUPPORT_PAYROLL_FAVORITE,
} from '../actions/types';

const initialState = {
  employeesResumeList: [],
  loademployeeObject: null,
  registerEmployeeObject: {
    editEmployeeStatus: false,
    openRegisterEmployee: false,
    isCompleteBasicData: false,
    isCompleteContractData: false,
    isCompletePaymentData: false,
    isCompleteNoviceData: false,
    responseCreateProcess: null,
    formData: {
      paymentData: null,
      basicData: null,
      contractData: null,
      novicesData: [],
    },
  },
  loadingRetireEmployee: false,
  loadingGetEmployees: false,
  loadingDetailEmployee: false,
  loadingCreateEmployee: false,
  reintegreStatus: false,
  employeeContractToFinish: false,
  filterEmployee: {
    contractToFinish: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEES:
      return {
        ...state,
        employeesResumeList: action.payload,
      };
    case LOAD_EMPLOYEE:
      return {
        ...state,
        loademployeeObject: action.payload,
      };
    case FILL_SUPPORT_EMPLOYEE_DETAIL:
      return {
        ...state,
        loademployeeObject: {
          ...state.loademployeeObject,
          lastSupports: action.payload,
        },
      };
    case UPDATE_CHANGE_STATUS:
      return {
        ...state,
        updateEmployeeResponse: action.payload,
      };
    case UPDATE_FAVORITE_EMPLOYEE:
      if (
        state.employeesResumeList &&
        action.payload.idEmployee &&
        state.loademployeeObject.id
      ) {
        const array = state.employeesResumeList.map((payroll) => {
          if (payroll.id === action.payload.idEmployee) {
            payroll = {
              ...payroll,
              favorito: action.payload.isFavorite,
            };
          }
          return payroll;
        });
        if (state.loademployeeObject.id === action.payload.idEmployee) {
          return {
            ...state,
            employeesResumeList: array,
            loademployeeObject: {
              ...state.loademployeeObject,
              favorito: action.payload.isFavorite,
            },
          };
        }
        return {
          ...state,
          employeesResumeList: array,
        };
      }
      return {
        ...state,
      };

    case RETIRE_EMPLOYEE:
      return {
        ...state,
        employeesResumeList: state.employeesResumeList.map((item) =>
          item.id === action.payload.id ? { ...item, retirado: 1 } : item,
        ),
      };
    case LOADING_RETIRE_EMPLOYEE:
      return {
        ...state,
        loadingRetireEmployee: action.payload,
      };
    case LOADING_GET_EMPLOYEES:
      return {
        ...state,
        loadingGetEmployees: action.payload,
      };
    case LOADING_DETAIL_EMPLOYEE:
      return {
        ...state,
        loadingDetailEmployee: action.payload,
      };
    case UPDATE_SUPPORT_PAYROLL_FAVORITE:
      if (action.payload.idSupportPayroll && state.loademployeeObject.id) {
        if (
          state.loademployeeObject.lastSupports.find(
            (support) => support.id === action.payload.idSupportPayroll,
          )
        ) {
          const array = state.loademployeeObject.lastSupports.map((support) => {
            if (support.id === action.payload.idSupportPayroll) {
              support = {
                ...support,
                favorito: action.payload.isFavorite,
              };
            }
            return support;
          });

          return {
            ...state,
            loademployeeObject: {
              ...state.loademployeeObject,
              lastSupports: array,
            },
          };
        }

        return {
          ...state,
        };
      }

      return {
        ...state,
      };
    case OPEN_REGISTER_EMPLOYEE:
      return {
        ...state,
        registerEmployeeObject: {
          ...state.registerEmployeeObject,
          openRegisterEmployee: action.payload,
        },
      };

    case SET_REINTEGRE_EMPLOYEE:
      return {
        ...state,
        reintegreStatus: action.payload,
      };
    case OPEN_MASSIVE_LOADING_EMPLOYEE:
      return {
        ...state,
        registerEmployeeObject: {
          ...state.registerEmployeeObject,
          openMassiveLoadingEmployee: action.payload,
        },
      };
    case SELECTED_ID_EMPLOYEE:
      return {
        ...state,
        employeeIdSelected: action.payload,
      };
    case LOAD_EMPLOYEE_DETAIL:
      return {
        ...state,
        loadEmployeeDetail: action.payload,
      };
    case CHANGE_EDITION_EMPLOYEE_STATE:
      return {
        ...state,
        registerEmployeeObject: {
          ...state.registerEmployeeObject,
          editEmployeeStatus: action.payload,
        },
      };
    case GET_EMPLOYEES_RESUME_LIST:
      return {
        ...state,
        employeesResumeList: action.payload,
      };
    // Register employee
    case SAVE_BASIC_DATA_EMPLOYEE:
      if (!action.payload) {
        return {
          ...state,
          registerEmployeeObject: {
            ...state.registerEmployeeObject,
            isCompleteBasicData: false,
            formData: {
              ...state.registerEmployeeObject.formData,
              basicData: null,
            },
          },
        };
      } else {
        return {
          ...state,
          registerEmployeeObject: {
            ...state.registerEmployeeObject,
            isCompleteBasicData: true,
            formData: {
              ...state.registerEmployeeObject.formData,
              basicData: action.payload,
            },
          },
        };
      }
    case SAVE_CONTRACT_DATA_EMPLOYEE:
      if (!action.payload) {
        return {
          ...state,
          registerEmployeeObject: {
            ...state.registerEmployeeObject,
            isCompleteContractData: false,
            formData: {
              ...state.registerEmployeeObject.formData,
              contractData: null,
            },
          },
        };
      } else {
        return {
          ...state,
          registerEmployeeObject: {
            ...state.registerEmployeeObject,
            isCompleteContractData: true,
            formData: {
              ...state.registerEmployeeObject.formData,
              contractData: action.payload,
            },
          },
        };
      }
    case SAVE_PAYMENT_DATA_EMPLOYEE:
      if (!action.payload) {
        return {
          ...state,
          registerEmployeeObject: {
            ...state.registerEmployeeObject,
            isCompletePaymentData: false,
            formData: {
              ...state.registerEmployeeObject.formData,
              paymentData: null,
            },
          },
        };
      } else {
        return {
          ...state,
          registerEmployeeObject: {
            ...state.registerEmployeeObject,
            isCompletePaymentData: true,
            formData: {
              ...state.registerEmployeeObject.formData,
              paymentData: action.payload,
            },
          },
        };
      }
    case SAVE_NOVELTIES_DATA_EMPLOYEE:
      if (!action.payload) {
        return {
          ...state,
          registerEmployeeObject: {
            ...state.registerEmployeeObject,
            isCompleteNoviceData: false,
            formData: {
              ...state.registerEmployeeObject.formData,
              novicesData: null,
            },
          },
        };
      } else {
        return {
          ...state,
          registerEmployeeObject: {
            ...state.registerEmployeeObject,
            isCompleteNoviceData: true,
            formData: {
              ...state.registerEmployeeObject.formData,
              novicesData: action.payload,
            },
          },
        };
      }
    case SAVE_RESPONSE_CREATE_PROCESS:
      return {
        ...state,
        registerEmployeeObject: {
          ...state.registerEmployeeObject,
          responseCreateProcess: action.payload,
        },
      };
    case RESET_REGISTER_FORM:
      return {
        ...state,
        registerEmployeeObject: {
          editEmployeeStatus: false,
          openRegisterEmployee: false,
          isCompleteBasicData: false,
          isCompleteContractData: false,
          isCompletePaymentData: false,
          isCompleteNoviceData: false,
          formData: {
            paymentData: null,
            basicData: null,
            contractData: null,
            novicesData: [],
          },
        },
      };
    case ADD_NEW_INCOME_EMPLOYEE:
      if (action.payload) {
        return {
          ...state,
          registerEmployeeObject: {
            ...state.registerEmployeeObject,
            formData: {
              ...state.registerEmployeeObject.formData,
              novicesData: [
                ...state.registerEmployeeObject.formData.novicesData,
                action.payload,
              ],
            },
          },
        };
      }
      return {
        ...state,
      };
    case ADD_NEW_EXPENSE_EMPLOYEE:
      if (action.payload) {
        return {
          ...state,
          registerEmployeeObject: {
            ...state.registerEmployeeObject,
            formData: {
              ...state.registerEmployeeObject.formData,
              novicesData: [
                ...state.registerEmployeeObject.formData.novicesData,
                action.payload,
              ],
            },
          },
        };
      }
      return {
        ...state,
      };
    case UPDATE_INCOME_EMPLOYEE:
      if (action.payload) {
        return {
          ...state,
          registerEmployeeObject: {
            ...state.registerEmployeeObject,
            formData: {
              ...state.registerEmployeeObject.formData,
              novicesData: action.payload,
            },
          },
        };
      }
      return {
        ...state,
      };
    case UPDATE_EXPENSE_EMPLOYEE:
      if (action.payload) {
        return {
          ...state,
          registerEmployeeObject: {
            ...state.registerEmployeeObject,
            isCompleteNoviceData: true,
            formData: {
              ...state.registerEmployeeObject.formData,
              novicesData: action.payload,
            },
          },
        };
      }
      return {
        ...state,
      };
    case DELETE_INCOME_EMPLOYEE:
      if (action.payload.conceptoid) {
        let array = state.registerEmployeeObject.formData.novicesData || [];

        const arrayFiltered = array.filter(
          (d) => d.conceptoid !== action.payload.conceptoid,
        );

        return {
          ...state,
          registerEmployeeObject: {
            ...state.registerEmployeeObject,
            formData: {
              ...state.registerEmployeeObject.formData,
              novicesData: arrayFiltered,
            },
          },
        };
      }
      return {
        ...state,
      };
    case DELETE_EXPENSE_EMPLOYEE:
      if (action.payload.id) {
        let array = state.payrollDetailEmployee.data.deducciones;

        const arrayFiltered = array.filter((d) => d.id !== action.payload.id);

        return {
          ...state,
          registerEmployeeObject: {
            ...state.registerEmployeeObject,
            formData: {
              ...state.registerEmployeeObject.formData,
              novicesData: arrayFiltered,
            },
          },
        };
      }
      return {
        ...state,
      };
    case types.LOADING_CREATE_EMPLOYEE:
      return {
        ...state,
        loadingCreateEmployee: action.payload,
      };
    case types.EMPLOYEE_CONTRACT_TO_FINISH:
      return {
        ...state,
        employeeContractToFinish: action.payload,
      };
    case types.SET_EMPLOYEE_FILTER:
      return {
        ...state,
        filterEmployee: {
          ...state.filterEmployee,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
