import React, { useCallback } from 'react';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// Material
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';

import ResponseModal
  from '../../../../../components/modal/responseModal.component';
import { useEmployees } from '../../../../../hooks/useEmployees';
import UploadIcon from '../../../../../images/icons/Upload.png';

const useStyles = makeStyles({
  container: {
    color: '#fff',
    flexGrow: 1,
  },
  containerGrid: {
    display: 'flex',
    flexWrap: 'inherit',
    gap: '3%',
  },
  itemGrid: {
    alignItems: 'center',
    borderRadius: '5px',
    backgroundColor: 'rgba(255, 255, 255, 0.18)',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    lineHeight: 0,
    minHeight: '40px',
    padding: '1%',
    textAlign: 'center',
  },
});

const ActionButtons = ({ setBulkLoad }) => {
  // style classes
  const classes = useStyles();
  // custom hook
  const { changeOpenRegisterEmployee, getLicenceDataEmployees } =
    useEmployees();
  const [modalData, setModalData] = React.useState({
    type: '',
    title: '',
    subtitle: '',
    body: '',
    modalImage: '',
    open: false,
    closeElement: '',
    onCloseElement: 0,
  });

  const onClickCreateEmployee = useCallback(() => {
    changeOpenRegisterEmployee(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLicenceDataEmployees]);

  const handleResponseModal = () => {
    setModalData({
      ...modalData,
      open: false,
    });
  };

  const onClickBulkUpload = () => {
    setBulkLoad(true);
  };

  return (
    <>
      <div className={classes.container}>
        <Grid container className={classes.containerGrid}>
          <Grid
            item
            className={classes.itemGrid}
            onClick={onClickCreateEmployee}
            xs={12}
            sm={6}
          >
            Crear nuevo empleado &nbsp;&nbsp;&nbsp;
            <AddCircleRoundedIcon></AddCircleRoundedIcon>
          </Grid>
          <Grid
            item
            className={classes.itemGrid}
            onClick={onClickBulkUpload}
            xs={12}
            sm={6}
          >
            Carga masiva de empleados &nbsp;&nbsp;&nbsp;
            <img src={UploadIcon} alt="Upload" />
          </Grid>
        </Grid>
      </div>
      <ResponseModal
        modalType={modalData.modalType}
        title={modalData.title}
        subtitle={modalData.subtitle}
        body={modalData.body}
        modalImage={modalData.modalImage}
        open={modalData.open}
        closeElement={modalData.closeElement}
        onCloseElement={handleResponseModal}
      />
    </>
  );
};

export default ActionButtons;
