import React from 'react';

export const AlertWarningIcon = ({ width = 25, height = 25, ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6806 0H7.31944L0 7.31944V17.6806L7.31944 25H17.6806L25 17.6806V7.31944L17.6806 0ZM12.5 20C11.4615 20 10.625 19.1635 10.625 18.125C10.625 17.0865 11.4615 16.25 12.5 16.25C13.5385 16.25 14.375 17.0865 14.375 18.125C14.375 19.1635 13.5385 20 12.5 20ZM13.75 13.75H11.25V5.625H13.75V13.75Z"
        fill="#F5D13D"
      />
    </svg>
  );
};
