import React, {
  Fragment,
  useEffect,
  useState,
} from 'react';

import PropTypes from 'prop-types';
//FizedSizeList
import { FixedSizeList } from 'react-window';

import {
  Box,
  Grid,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';

import InputIcon from '../../components/input/inputIcon.component';
import CustomProgress from '../../components/Progress/progress.component';
import FilterEmployee from '../_Employee/filter/FilterEmployee';
import PopoverModal from '../modal/popoverModal.component';

const filterImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAD6ADAAQAAAABAAAAEAAAAADlhttaAAAA3klEQVQoFWO8cePGfwYyARNQ3zFy9DIyMp5gYmNjCwJqfkSKAUCNL4HqQ5iUlJReMjEx+QM5X4k04A9Qc5i6uvpTkLMZ1NTULgANiAMKEvQ/UF0JUP0hkD6wZqgB64Ca60BsPGAZUONEmDxcM0gAKNECNGAFTBKZBopf4uPjS0UWQ9EMkuDi4koCUmeQFQE1fgDiQCkpqW/I4hiaZWVlvwMVrkNWBGSfBbrqHpoYws/oEsTwMWwmRhNMzSDXDAzAAzCnItPEOLsbFP/ImmBsFhgDGw20sQ+YhsuwyYHEAPSMI2AQGhdxAAAAAElFTkSuQmCC';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    backgroundColor: theme.palette.thirdBackgroundColor,
    borderRadius: '5px',
    color: theme.palette.primaryColor,
    border: 'none',
  },
  filterContainer: {
    marginBottom: theme.spacing(2),
  },
  filterButtonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  listStyle: {
    backgroundColor: theme.palette.secundaryBackgroundColor,
    color: theme.palette.primaryColor,
  },
  item: {
    marginBottom: 5,
    width: '99%',
    fontWeight: 600,
    '&.MuiListItem-root:hover': {
      borderRadius: 5,
      border: '1px solid #16B1F6',
    },
    '&.Mui-selected': {
      backgroundColor: '#16B1F3',
      borderRadius: 5,
    },
  },
  itemText: {
    fontWeight: 600,
    color: theme.palette.primaryColor,
  },
  textOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600,
    maxWidth: '150vh',
  },
  listContainer: {
    backgroundColor: '#232323',
  },
  virtualList: {
    width: '100% !important',
    position: 'initial',
    overflowX: 'hidden',
    backgroundColor: 'transparent',
    borderRadius: 5,
  },
}));

const useStylesBootstrap = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: '#2F2E2E',
    fontSize: '14px',
    boxShadow: '0px 0px 5px 2px rgba(0,0,0,0.5)',
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip classes={classes} {...props} />;
}

/**
 * Filtra lista de acuerdo a parametros
 * @param {*} list Listado
 * @param {*} filter cadena para filtro
 */
function handleFilterList(list, filter, top) {
  if (!!filter && filter !== '') {
    list = list.filter(
      (c) =>
        c.text.toLowerCase().match(new RegExp(filter.toLowerCase() + '.*')) ||
        c.documento
          .toLowerCase()
          .match(new RegExp(filter.toLowerCase() + '.*')),
    );
  }

  if (top === 0 || top === null || top === undefined) {
    return list;
  } else {
    return list.slice(0, top);
  }
}

/**
 * Lista con filtro
 * Autor:Fernando Ruiz
 * @method function
 * @param {array}list  Listado original
 * @param {event}onClick Evento click al seleccionar un item de la lista
 * @param {boolean} loading  visualzación de progress
 * @param {int} top  Maximo de item a mostrar
 */

function ListWithFilter({
  list,
  onClick,
  loading,
  top,
  maxHeight = 800,
  selectedId,
  textSearch,
  showFilter = true,
}) {
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState(null);
  const [filter, setFilter] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [modalFilter, setModalFilter] = useState(null);

  useEffect(() => {
    if (!!list && list.length) {
      setFilterList(list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    setSelectedItem(selectedId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  const handleFilter = (e) => {
    const value = e.target.value;
    let newList = handleFilterList(list, value, top);
    setFilter(value);
    setFilterList(newList);
  };

  const handleListItemClick = (event, id) => {
    setSelectedItem(id);
  };

  const Row = ({ index, style }) => {
    const item = filterList[index];
    return (
      <div style={style}>
        <ListItem
          className={classes.item}
          button
          selected={selectedItem === item.id}
          onClick={(event) => {
            handleListItemClick(event, item.id);
            onClick(item);
          }}
        >
          <ListItemText className={classes.itemText}>
            <BootstrapTooltip title={`${item.text || ''}`}>
              <div className={classes.textOverflow}>{item.text}</div>
            </BootstrapTooltip>
          </ListItemText>
        </ListItem>
      </div>
    );
  };

  /**
   * Abre filtro por estado
   * @param {*} event
   */
  const handleOpenModalChecks = (event) => {
    setModalFilter(event.currentTarget);
  };

  /**
   * Cierra filtro estado
   */
  const handleCloseModalChecks = () => {
    setModalFilter(null);
  };

  return (
    <Box>
      <Grid container spacing={2} className={classes.filterContainer}>
        <Grid item xs={11} sm={showFilter ? 11 : 12}>
          <InputIcon
            name="txtFilter"
            label={textSearch || 'Buscar empleado por nombre o documento'}
            maxLength={28}
            value={filter}
            onChange={handleFilter}
            style={{
              whiteSpace: 'nowrap',
              overFlow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            fullWidth
          />
        </Grid>

        {showFilter && (
          <Grid item xs={1} sm={1} className={classes.filterButtonContainer}>
            <IconButton
              onClick={handleOpenModalChecks}
              style={{ padding: '0 0 6px 0' }}
            >
              <img alt="Filtrar" width="18.75px" src={filterImage} />
            </IconButton>
          </Grid>
        )}
      </Grid>

      {loading ? (
        <CustomProgress />
      ) : (
        <Fragment>
          {filterList.length ? (
            <div
              className={`${classes.root} ${classes.listContainer}`}
              style={{
                maxHeight: maxHeight,
                minHeight: maxHeight,
              }}
            >
              <List
                className={classes.listStyle}
                component="nav"
                aria-label="main mailbox folders"
              >
                <FixedSizeList
                  width="100%"
                  height={maxHeight - 42}
                  itemCount={filterList.length}
                  itemSize={65}
                  className={classes.virtualList}
                >
                  {Row}
                </FixedSizeList>
              </List>
            </div>
          ) : (
            <Box py={2}>No se han encontrado resultados...</Box>
          )}
        </Fragment>
      )}

      <PopoverModal
        handleClose={handleCloseModalChecks}
        open={Boolean(modalFilter)}
        component={FilterEmployee}
        componentProps={{ handleClose: handleCloseModalChecks }}
        width={191}
        popoverAnchorEl={modalFilter}
        showCloseButton={false}
      />
    </Box>
  );
}

ListWithFilter.propTypes = {
  list: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  top: PropTypes.number,
  maxHeight: PropTypes.number,
  selectedId: PropTypes.any,
  textSearch: PropTypes.string,
  showFilter: PropTypes.bool,
};

export default ListWithFilter;
