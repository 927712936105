import React, { useState, Fragment } from 'react';
import { Card, CardHeader, CardActions, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';
import ButtonSecundary from '../button/buttonSecundary.component';
import ButtonPrimary from '../button/buttonPrimary.component';
import clsx from 'clsx';
import { enterpriseIdHeader } from '../../actions/authActions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 500,
        backgroundColor: 'rgba(255,255,255,0.11)',
        color: theme.palette.primaryColor,
        fontWeight: 300,
    },
    header: {
        alignItems: 'normal',
        paddingBottom: 0,
    },
    headerContent: {
        textOverflow: 'ellipsis',
        minHeight: 130,
    },
    question: {
        height: '52px',
        width: '418px',
        color: '#16B1F3',
        fontFamily: 'Muli',
        fontSize: '18px',
        fontWeight: 'bold',
        letterSpacing: 0,
        lineHeight: '26px',
    },
    answer: {
        height: '63px',
        width: '418px',
        color: '#FFFFFF',
        fontFamily: 'Muli',
        fontSize: '14px',
        fontWeight: 300,
        letterSpacing: 0,
        lineHeight: '20px',
    },
    icon: {
        color: '#16B1F3',
        fontFamily: 'Muli',
        fontWeight: 300,
        letterSpacing: 0,
        lineHeight: '18px',
    },
    actions: {
        justifyContent: 'flex-end',
        padding: 'unset',
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandIcon: {
        '& .MuiSvgIcon-root': {
            fontSize: 33,
        },
    },
    dialog: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        '& .MuiPaper-root': {
            backgroundColor: '#393939',
            color: theme.palette.primaryColor,
        },
    },
    backgroundContent: {
        color: theme.palette.primaryColor,
    },
    subTitle: {
        color: theme.palette.primaryColor,
        fontSize: 17,
    },
    title: {
        color: theme.palette.thirdColor,
    },
    containerActions: {
        justifyContent: 'end',
        alignItems: 'center',
    },
}));

const ReportComponentEmployee = ({ title, subtitle, action }) => {
    const [openModal, setOpenModal] = useState(false);
    const classes = useStyles();
    const handleOpenModal = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
    const [loading, setLoading] = useState(false);
    const changeLoadingStatus = (status) => setLoading(status);
    const dispatch = useDispatch();

    const getDataReportEmployee = () => {
        const empresaId = enterpriseIdHeader();
        if (!empresaId) {
            alert('No se pudo obtener un ID de empresa válido. Por favor, verifique la configuración.');
            return;
        }
        dispatch(action(empresaId, changeLoadingStatus));
    };

    return (
        <>
            <Card className={classes.root}>
                <CardHeader
                    className={classes.header}
                    classes={{
                        title: classes.question,
                        content: classes.headerContent,
                    }}
                    title={
                        <Fragment>
                            <Typography variant="body1">
                                <Fragment>
                                    <span className={classes.question}>
                                        {title}
                                        <br />
                                        <br />
                                    </span>
                                </Fragment>
                                <Fragment>
                                    <span className={classes.answer}>
                                        {subtitle}
                                        <br />
                                    </span>
                                </Fragment>
                            </Typography>
                        </Fragment>
                    }
                />
                <CardActions className={classes.actions}>
                    <IconButton className={classes.expandIcon} onClick={handleOpenModal}>
                        <Typography variant="caption" className={classes.icon}>
                            Descargar
                        </Typography>
                        <ExpandMore className={clsx(classes.expand, {}, classes.icon)} />
                    </IconButton>
                </CardActions>
                <Dialog
                    open={openModal}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                    className={classes.dialog}
                >
                    <DialogTitle id="form-dialog-title">
                        <span className={classes.title}>{title}</span>
                    </DialogTitle>
                    <DialogContent className={classes.backgroundContent}>
                        <DialogContentText>
                            <span className={classes.subTitle}>{subtitle}</span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="backgroundContent">
                        <Grid container className={classes.containerActions}>
                            <Grid item xs={4} lg={4}>
                                <ButtonSecundary text={'Cancelar'} onClick={handleClose} />
                            </Grid>
                            <Grid item lg={4} xs={4}>
                                <ButtonPrimary
                                    text={'Descargar'}
                                    disabled={loading}
                                    type={'button'}
                                    onClick={getDataReportEmployee} />
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </Card>
        </>
    );
};

export default ReportComponentEmployee;
