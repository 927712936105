import React from 'react';

import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import { CloseIcon } from '../../../components/icon/ErrorShapeIcon';
import { AlertWarningIcon } from '../../../components/icon/ShapeAlertWarning';

const useStyles = makeStyles((theme) => ({
  alert: {
    backgroundColor: '#FFFFFF1F',
    '& .MuiAlert-icon': {
      color: theme.palette.warning.main,
    },
    alignItems: 'center',
    margin: '1.5rem 0.3rem 1rem 1.8rem',
  },
  alertIcon: {
    margin: '0 1.5rem 0 0.5rem',
  },
  closeIcon: {
    margin: '0 0.5rem 0 0',
    cursor: 'pointer',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  message: {
    flex: 1,
    color: '#F5D13D',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'left',
    textUunderlinePosition: 'from-font',
  },
  link: {
    cursor: 'pointer',
    color: '#2d9cdd',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const BannerEmployee = ({ onClose, onClickHere }) => {
  const classes = useStyles();

  return (
    <Alert
      severity="warning"
      className={classes.alert}
      icon={
        <AlertWarningIcon
          width="25px"
          height="25px"
          className={classes.alertIcon}
        />
      }
      action={<CloseIcon onClick={onClose} className={classes.closeIcon} />}
    >
      <Box className={classes.content}>
        <span className={classes.message}>
          Algunos de tus empleados finalizan su contrato en el transcurso de
          este mes, al cumplir ese tiempo se retirarán automáticamente de la
          plataforma. En caso de continuar trabajando, por favor revísalos y de
          ser necesario actualiza su información dando{' '}
          <Link
            component="button"
            onClick={onClickHere}
            className={classes.link}
          >
            clic aquí
          </Link>
          .
        </span>
      </Box>
    </Alert>
  );
};

export default BannerEmployee;
