import React from 'react';
import { Grid } from '@material-ui/core';
import ReportComponent from '../../components/informe/ReportComponent';
import ReportComponentEmployee from '../../components/informe/ReportComponentEmployee';
import { downloadPayrollGroupedReport, downloadPayrollReportEmployee } from '../../actions/payrollReportAction';
import ExcelPayrollReport from './excelData/ExcelPayrollReport';

const ReportsView = () => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item lg={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={5} md={12} xs={12} style={{ paddingTop: '1rem' }}>
              <ReportComponent
                title={'Informe Nómina Electrónica'}
                subtitle={
                  'En este informe tendrá los datos específicos sobre las nóminas exitosas enviadas a la DIAN que se generaron en su empresa.'
                }
                ExcelComponent={ExcelPayrollReport}
                action={downloadPayrollGroupedReport}
                reportTitle={'informeNomina'}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item lg={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={5} md={12} xs={12} style={{ paddingTop: '1rem' }}>
              <ReportComponentEmployee
                title={'Informe para Empleados'}
                subtitle={
                  'En este informe tendrá los datos específicos de los empleados registrados en su empresa.'
                }
                ExcelComponent={ExcelPayrollReport}
                action={downloadPayrollReportEmployee}
                reportTitle={'informeNominaEmpleado'}
                showDocumentField={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ReportsView;
