import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  ThemeProvider,
  createMuiTheme,
  makeStyles,
} from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  Typography,
  CardActions,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
  DialogContentText,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import esLocale from 'date-fns/locale/es';
import DateFnsUtils from '@date-io/date-fns';
import ButtonSecundary from '../button/buttonSecundary.component';
import ButtonPrimary from '../button/buttonPrimary.component';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
    backgroundColor: 'rgba(255,255,255,0.11)',
    color: theme.palette.primaryColor,
    fontWeight: 300,
  },
  header: {
    alignItems: 'normal',
    paddingBottom: 0,
  },
  headerContent: {
    textOverflow: 'ellipsis',
    minHeight: 130,
  },
  question: {
    height: '52px',
    width: '418px',
    color: '#16B1F3',
    fontFamily: 'Muli',
    fontSize: '18px',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '26px',
  },
  icon: {
    color: '#16B1F3',
    fontFamily: 'Muli',
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: '18px',
  },
  dialog: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    '& .MuiPaper-root': { backgroundColor: '#393939', color: theme.palette.primaryColor },
  },
  subTitle: {
    color: theme.palette.primaryColor,
    fontSize: 17,
  },

  title: {
    color: theme.palette.thirdColor,
  },
  answer: {
    height: '63px',
    width: '418px',
    color: '#FFFFFF',
    fontFamily: 'Muli',
    fontSize: '14px',
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: '20px',
  },
  actions: {
    justifyContent: 'flex-end',
    padding: 'unset',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: 33,
    },
  },
  dialog: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    '& .MuiPaper-root': {
      backgroundColor: '#393939',
      color: theme.palette.primaryColor,
    },
  },
  backgroundContent: {
    color: theme.palette.primaryColor,
  },
  containerActions: {
    justifyContent: 'end',
    alignItems: 'center',
  },
}));

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: { toolbar: { backgroundColor: 'rgb(22, 177, 243)' } },
    MuiInputBase: {
      root: { color: '#FFF', '& fieldset': { borderColor: '#16B1F3' } },
    },
    MuiInputLabel: {
      root: {
        color: '#FFF',
      },
    },
  },
});

const ReportComponent = ({ title, subtitle, action }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const changeLoadingStatus = (status) => setLoading(status);

  const handleOpenModal = () => setOpenModal(true);
  const handleClose = () => {
    if (!loading) setOpenModal(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const getDataReportGroup = () => {
    const startDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
    const endDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);

    const formattedStartDate = startDate.toISOString().split('T')[0];
    const formattedEndDate = endDate.toISOString().split('T')[0];

    dispatch(
      action(formattedStartDate, formattedEndDate, changeLoadingStatus, (hasData) => {
        setLoading(false);
        if (hasData) {
          setOpenModal(false);
        }
      })
    );
  };

  const handleCloseError = () => setError(null);

  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          className={classes.header}
          classes={{
            title: classes.question,
            content: classes.headerContent,
          }}
          title={
            <Typography variant="body1">
              <span className={classes.question}>{title}</span>
              <br />
              <span className={classes.answer}>
                {subtitle}
                <br />
              </span>
            </Typography>
          } />
        <CardActions className={classes.actions}>
          <IconButton className={classes.expandIcon} onClick={handleOpenModal}>
            <Typography variant="caption" className={classes.icon}>
              Descargar
            </Typography>
            <ExpandMore className={clsx(classes.expand, {}, classes.icon)} />
          </IconButton>
        </CardActions>
        <Dialog open={openModal} onClose={handleClose} className={classes.dialog}>
          <DialogTitle id="form-dialog-title">
            <span className={classes.title}>{title}</span>
          </DialogTitle>
          <DialogContent className={classes.backgroundContent}>
            <DialogContentText>
              <span className={classes.subTitle}>{subtitle}</span>
            </DialogContentText>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <ThemeProvider theme={materialTheme}>
                <DatePicker
                  views={['year', 'month']}
                  label="Seleccione Mes y Año"
                  inputVariant="outlined"
                  value={selectedDate}
                  onChange={handleDateChange}
                  format="MMMM yyyy"
                  disableFuture
                  minDate={new Date(2000, 0, 1)}
                  maxDate={new Date()} />
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </DialogContent>
          <DialogActions className="backgroundContent">
            <ButtonSecundary text="Cancelar" onClick={handleClose} />
            <ButtonPrimary
              text="Descargar"
              loading={loading}
              onClick={getDataReportGroup} />
          </DialogActions>
        </Dialog>
      </Card>
      <Snackbar
        open={!!error}
        message={error}
        onClose={handleCloseError}
        autoHideDuration={6000}
      />
    </>
  );
};
export default ReportComponent;
