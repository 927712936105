import React from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  Grid,
  Link,
  makeStyles,
} from '@material-ui/core';

import { setEmployeeFilter } from '../../../actions/employeeActions';
import { types } from '../../../actions/types';
import StyledCheckbox from '../../checkForm/styledCheckbox.component';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflowY: 'hidden',
    overflowX: 'hidden',
    height: '68.5vh',
    minHeight: '68.5vh',
    color: '#FFFF',
    backgroundColor: 'rgba(255, 255, 255, 0.11)',
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 5,
  },
  customItem: {
    '&:hover': {
      border: '1px solid #16B1F3',
      borderRadius: 5,
    },
    borderRadius: 5,
    marginBottom: 5,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 10px',
  },
  customItemSelected: {
    backgroundColor: '#16B1F3 !important',
  },
  itemFilter: {
    marginBottom: 5,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 10px',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  itemText: {
    fontSize: 18,
    fontWeight: 700,
    padding: '10px 5px',
    marginTop: 0,
    marginBottom: 0,
    textAlign: 'left',
    color: '#FFFFFF',
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  actions: {
    width: '100%',
    textAlign: 'end',
  },
  checkItem: {
    width: '100%',
    textAlign: 'left',
  },
  letter: {
    color: '#FFFFFF',
  },
  link: {
    color: 'White',
    fontSize: '16px',
    padding: '7px',
    textAlign: 'center',
  },
}));

const FilterEmployee = ({ handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filterEmployee = useSelector((s) => s.employeeReducer.filterEmployee);

  const handleCheckFilter = (event) => {
    dispatch(
      setEmployeeFilter({
        ...filterEmployee,
        contractToFinish: event.target.checked,
      }),
    );
    handleClose();
  };

  const handleCleanFilter = () => {
    dispatch({
      type: types.SET_EMPLOYEE_FILTER,
      payload: {
        ...filterEmployee,
        contractToFinish: false,
      },
    });
    handleClose();
  };

  return (
    <div style={{ paddingLeft: 10, fontSize: 16 }}>
      <Grid container justify="flex-start" alignItems="center">
        <Grid item lg={3} md={3} sm={3}>
          <StyledCheckbox
            className={classes.letter}
            checked={filterEmployee.contractToFinish}
            onChange={handleCheckFilter}
            name="contractToFinish"
          />
        </Grid>
        <Grid item lg={9} md={9} sm={9}>
          Retiran este mes
        </Grid>

        <Grid item lg={12} md={12} sm={12} className={classes.link}>
          <Link
            href="#"
            onClick={handleCleanFilter}
            variant="inherit"
            underline="none"
            className={classes.link}
          >
            Limpiar Filtro
          </Link>
        </Grid>
      </Grid>
    </div>
  );
};

export default FilterEmployee;
