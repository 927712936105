import { errorToast, infoToast, warningToast } from '../helpers/toast.helper';

export const showErrorMessage = (error, customMessage) => {
  const status = error?.response?.status ?? '';
  const errorMessage = !!error?.response ? error?.response : error;

  console.log(customMessage, errorMessage);

  switch (status.toString()) {
    case '500':
      errorToast('Error', customMessage);
      break;
    case '510':
      warningToast('No hay registros', customMessage);
      break;
    case '400':
      warningToast('Petición invalida', customMessage);
      break;
    case '404':
      warningToast('Servicio no encontrado', customMessage);
      break;
    case '401':
      errorToast('Usuario no autorizado"', customMessage);
      break;
    case '422':
      infoToast(
        'No se puede procesar el contenido de la solicitud',
        customMessage,
      );
      break;
    case '204':
      warningToast('Sin Contenido', customMessage);
      break;
    default:
      errorToast(
        'Upsss...!!"',
        'Ha ocurrido un error no identificado, Por favor contacte al administrador',
      );
      break;
  }
};
