import { getFullName } from '../../../helpers/functions.helpers';

/**
 * Arma nombre completo para listado empleados
 * @param {*} arrayDestructure
 * @returns {Array} Array con propiedades de item y text
 */
export const destructureArrayFilter = (arrayDestructure) => {
  const array = arrayDestructure.map((item) => ({
    ...item,
    text: getFullName(
      item.primerApellido,
      item.segundoApellido,
      item.primerNombre,
      item.otroNombre,
    ),
  }));
  return array;
};
